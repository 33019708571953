var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height"},[_c('AdminNavbar'),(_vm.user)?[_c('v-main',{staticClass:"fill-height"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"pb-2"},[_c('v-col',{attrs:{"sm":"10","offset-sm":"1"}},[_c('v-card',{staticClass:"card--flex-toolbar"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-icon',{attrs:{"large":"","left":""}},[_vm._v("person")]),_c('v-toolbar-title',[_c('h1',{staticClass:"headline"},[_vm._v("Editar Usuario")]),_c('h3',{staticClass:"subtitle-1",domProps:{"textContent":_vm._s(_vm.user && _vm.user.email)}})])],1),(_vm.$apollo.loading)?_c('Loading'):[_c('v-card-text',[_c('Fields',{attrs:{"schema":{
                      roles: {
                        type: 'select',
                        label: 'Roles',
                        fieldOptions: {
                          multi: true,
                          options: [
                            { value: 'admin', label: 'Administrador' },
                            { value: 'superAdmin', label: 'Súper Administrador' } ]
                        }
                      },
                      environmentsAuthorized: {
                        type: 'select',
                        label: 'Entornos Autorizados',
                        fieldOptions: {
                          multi: true,
                          options: _vm.environmentsAvailable
                        }
                      }
                    },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{},"validationErrors":{}},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","icon":"","color":"error","disabled":_vm.$apollo.loading || _vm.saving,"title":"Eliminar Usuario"},on:{"click":_vm.deleteItem}},[_c('v-icon',[_vm._v("delete")])],1),_c('v-btn',{attrs:{"text":"","icon":"","title":"Revertir Cambios","disabled":!_vm.dirty || _vm.saving},on:{"click":_vm.update}},[_c('v-icon',[_vm._v("undo")])],1),_c('v-flex'),_c('v-btn',{attrs:{"text":"","disabled":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.dismiss}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":!_vm.dirty,"loading":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.save}},[_vm._v("Guardar")])],1)]],2)],1)],1)],1)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }