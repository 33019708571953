































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import md5 from 'md5'
import GlobalSearch from '@/components/GlobalSearch.vue'
import AdminNavbar from '@/components/AdminNavbar.vue'
import Loading from '@/components/Loading.vue'
import Fields from '@/components/form/Fields.vue'
import gql from 'graphql-tag'
import { Environment, User, PaginatedQueryResult } from '@/models'
import { ApolloQueryResult } from 'apollo-client'
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import { confirmDelete } from '@/components/dialogs'

@Component({
  components: {
    GlobalSearch,
    AdminNavbar,
    Loading,
    Fields
  },
  apollo: {
    savedUser: {
      query: gql`query getUser ($userId : ID) {
        savedUser: user(userId: $userId) {
          _id
          email
          environmentsAuthorized
          roles
        }
      }`,
      variables () {
        return {
          userId: this.userId
        }
      }
    },
    environments: gql`query {
      environments {
        items {
          value: _id
          label: name
        }
      }
    }`
  }
})
export default class AdminUser extends Vue {
  environment : Environment | null = null

  @Prop({ type: String }) userId ?: string
  savedUser : User | null = null
  user : Partial<User> = {}
  saving = false
  valid = false
  environments : Partial<PaginatedQueryResult<Environment>> = {
    items: []
  }

  get environmentsAvailable () {
    return this.environments.items || []
  }

  @Watch('savedUser')
  update (newData : User) {
    this.$set(this, 'user', _cloneDeep(this.savedUser))
  }

  async save () {
    if (!this.user || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`mutation ($userId : ID, $roles : [ID], $environmentsAuthorized: [ID]) {
          setUserAuthorities (userId: $userId, roles: $roles, environmentsAuthorized: $environmentsAuthorized) {
            _id
            email
            roles
            environmentsAuthorized
          }
        }`,
        // Parameters
        variables: {
          userId: this.user._id,
          roles: this.user.roles,
          environmentsAuthorized: this.user.environmentsAuthorized
        }
      })

      this.savedUser = result.data.setUserAuthorities
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Se ha actualizado el usuario.'
      })
      await this.$router.push('/')
    } catch (e) {
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Error: ' + e.message,
        color: 'error'
      })
      console.error(e)
    } finally {
      this.saving = false
    }
  }

  async deleteItem () {
    if (!await confirmDelete('¿Seguro que quieres eliminar por completo este usuario del sistema?')) return
    if (!this.user || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`mutation ($userId : ID) {
          removeUser (userId: $userId)
        }`,
        // Parameters
        variables: {
          userId: this.user._id
        }
      })
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Se ha eliminado el usuario.'
      })
      await this.$router.push('/')
    } catch (e) {
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Error: ' + e.message,
        color: 'error'
      })
      console.error(e)
    }
  }

  get dirty () {
    return !_isEqual(this.user, this.savedUser)
  }

  dismiss () {
    this.$router.back()
  }
}
